<template>
      <BrandView
    brand="alkkemistGin"
    type="alcohol"
    logo="alcohol/alkkemistGin/logo.png"
    cover="alcohol/alkkemistGin/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "AlkkemistGin",
  components: { BrandView },
};
</script>
