<template>
      <BrandView
    brand="theIllusionist"
    type="alcohol"
    logo="alcohol/theIllusionist/logo.png"
    cover="alcohol/theIllusionist/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "TheIllusionist",
  components: { BrandView },
};
</script>
