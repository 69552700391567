import { render, staticRenderFns } from "./SatrynaTequilaMezcal.vue?vue&type=template&id=085ac63d&scoped=true&"
import script from "./SatrynaTequilaMezcal.vue?vue&type=script&lang=js&"
export * from "./SatrynaTequilaMezcal.vue?vue&type=script&lang=js&"
import style0 from "./SatrynaTequilaMezcal.vue?vue&type=style&index=0&id=085ac63d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "085ac63d",
  null
  
)

export default component.exports