<template>
  <BrandView brand="guyMichelAndFils" type="champagne" logo="champagne/guyMichelAndFils/Guy-Michel_logo_light.svg"
    cover="champagne/guyMichelAndFils/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "GuyMichelAndFils",
  components: { BrandView },
};
</script>
