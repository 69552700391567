var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('AgeWarning'),_c('Locale'),_c('vue-snotify'),_c('router-view'),(
      this.$route.name === 'Home' ||
      //alcohol
      this.$route.name === 'Caffo' ||
      this.$route.name === 'Number Zero' ||
      this.$route.name === 'Satryna' ||
      this.$route.name === 'Scapegrace' ||
      this.$route.name === 'Knut Hansen' ||
      this.$route.name === 'Adamus' ||
      this.$route.name === 'Panda Organic Gin' ||
      this.$route.name === 'Alkkemist Gin' ||
      this.$route.name === 'Unkkoded Vodka' ||
      this.$route.name === 'The Illusionist Dry Gin' ||
      this.$route.name === 'Gran Chaco' ||
      this.$route.name === 'Engine Gin' ||
      //champagne
      this.$route.name === 'Philipponnat' ||
      this.$route.name === 'Charles Heidsieck' ||
      this.$route.name === 'Aubry' ||
      this.$route.name === 'Deville' ||
      this.$route.name === 'Francis Orban' ||
      this.$route.name === 'Andre Beaufort' ||
      this.$route.name === 'Cattier' ||
      this.$route.name === 'Franck Pascal' ||
      this.$route.name === 'Laherte Freres' ||
      this.$route.name === 'Bruno Michel' ||
      this.$route.name === 'Domaine Collet' ||
      this.$route.name === 'Vincent Couche' ||
      this.$route.name === 'Guy Michel & Fils' ||
      this.$route.name === 'J Charpentier'
    )?_c('TheFooter',{attrs:{"id":"contacts"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }