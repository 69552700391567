<template>
  <div class="content" v-observe-visibility="visibilityChanged">
    <section class="champagne__content parent">
      <div
        class="text__champagne parent__text"
        :class="{ text__visible: visible }"
      >
        <h1>{{ $t("champagne.title") }}</h1>
        <p>{{ $t("champagne.desc") }}</p>
      </div>

      <div
        class="champagne__products parent__products"
        :class="{ text__visible: visible }"
      >

        <Product       
          v-for="c in champagnes"
          :key="c.name"
          :product="c"
          />

        <router-link to="/champagnes" class="to__brands">
          <main>
            <section>
              <img src="@/assets/img/home/next.svg" alt="" />
              <div class="text">
                <h1>{{ $t("champagne.title") }}</h1>
                <p>{{ $t("champagne.desc2") }}</p>
              </div>
            </section>
          </main>
        </router-link>
      </div>
    </section>

    <section class="images">
      <p class="image" :class="{ image__visible: visible }"></p>
      <p class="imageOver" :class="{ imageOver__visible: visible }"></p>
    </section>
  </div>
</template>

<script>
import Product from '@/components/Product';
export default {
  name: "Champagne",
      components: {
    Product
  },
  data() {
    return {
      visible: false,

       champagnes: [
        {
          name: "philipponnat",
          number: "first",
          folder: "philipponnat",
          logo: "philipponnat_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#bf9e4b',
          type:"champagne"
        },
          {
            name: "charles",
            number: "second",
            folder: "charles",
            logo: "charlesheidsieck_dark.svg",
            bottle: "product_1",
            right: false,
            color:'#495872',
            type:"champagne"
          },
        {
          name: "aubry",
          number: "third",
          folder: "aubry",
          logo: "aubry_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#f3b615',
          type:"champagne"
        },
        {
          name: "deville",
          number: "fourth",
          folder: "deville",
          logo: "deville_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#d2ae59',
          type:"champagne"
        },
       ]
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/mainPage.scss";
.content {
  height: 140vh;
  min-height: 1500px;
  .images {
    .image {
      background: url("../assets/img/home/h3.jpg") no-repeat;
      background-size: cover;
      background-position: center;
    }
    .imageOver {
      background: url("../assets/img/home/h3.jpg") no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  .champagne__content {
    &::before {
      content: "";
      background: url("../assets/img/home/h3.jpg") no-repeat;
    }
    .text__champagne {
      transform: translateX(-20%);

      &::before {
        background: url("../assets/img/contacts/quote_1.svg") no-repeat;
      }
    }
    .champagne__products {
      transform: translateX(-20%);

    }
  }
}
@media all and(max-width:$mobile) {
  .content {
    height: 100%;
  }
}
</style>
