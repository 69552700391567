<template>
  <transition name="appear" appear>
    <main class="video__container" v-if="warningModalOpen">
      <div class="video">
        <video id="myVideo" playsinline autoplay muted loop>
          <source src="../assets/img/home/bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="video__content">
        <img src="../assets/img/logo.svg" alt="" />

        <div class="btn__parent">
          <button @click="accept()" >
            <h1>{{ $t("ageWarning.yes") }}</h1>
          </button>
          <a  href="http://www.google.com/search">{{ $t("ageWarning.no") }}</a>
        </div>
      </div>
    </main>
  </transition>
</template>
<script>
export default {
  data() {
    return {
      warningModalOpen: false,
    };
  },
  methods: {
    accept() {
      localStorage.setItem("termsAccepted", true);
      this.warningModalOpen = false;
      document.body.classList.remove("noscroll");
    },
    isMobileMode() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    const accepted = localStorage.getItem("termsAccepted");
    if (!accepted) {
      this.warningModalOpen = true;
      document.body.classList.add("noscroll");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
.video__container {
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  z-index: 50;

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    video {
      min-width: 100vw;
      width: fit-content;
    }
  }
  .video__content {
    align-items: center;
    background: rgba(21, 21, 21, 0.85) 0% 0%;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    padding: 0 20px;
    @include noselect();

    img {
      width: 100%;
      height: 300px;
    }

    .btn__parent {
      display: flex;
      flex-direction: column;
      gap: 10px;

      button {
        background: #d2b349 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 10px;
        height: 60px;
        @include tr();
        h1 {
          padding: 0 50px;
          font-size: 17px;
          @include tr();
          font: normal normal normal 17px Oswald;
        }
        &:hover {
          transform: scale(0.96);
          h1 {
            transform: scale(1.1);
          }
        }
      }
      a {
        color: #707070;
        text-decoration: underline;
        width: fit-content;
        margin: 0 auto;
        font: normal normal normal 14px Oswald;
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .video__container {
      height: 100%;
    &::before {
      content: "";
      background: url("../assets/img/home/home-mobile-bg.jpg") no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -20;
      width: 100%;
      height: 100%;
    }
    .video {
      display: none;
    }
    .video__content {
      background: rgba(243, 242, 247, 0.1) 0% 0%;

      h1 {
          font-size: 13px;
      }
      img {
        height: 200px;
      }
    }
  }
}
</style>