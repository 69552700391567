<template>
  <BrandView brand="domaineCollet" type="champagne" logo="champagne/domaineCollet/Domaine-Collet_logo_light.svg"
    cover="champagne/domaineCollet/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "DomaineCollet",
  components: { BrandView },

};
</script>
