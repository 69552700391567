<template>
      <BrandView
    brand="granChaco"
    type="alcohol"
    logo="alcohol/granChaco/logo.svg"
    cover="alcohol/granChaco/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "GranChaco",
  components: { BrandView },

};
</script>
