<template>
  <BrandView
    brand="knutHansen"
    type="alcohol"
    logo="alcohol/knutHansen/KnutHansen_Logo_light.svg"
    cover="alcohol/knutHansen/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "KnutHansen",
  components: { BrandView },
};
</script>

