<template>
    <section class="products__content">
      <div class="header__products">
        <router-link to="/" class="logo">
          <img class="logo" src="../assets/img/logo-b.svg" alt="logo" />
        </router-link>
        <h1>{{ $t("champagne.title") }}</h1>
        <p>{{ $t("champagne.desc") }}</p>
      </div>

      <div class="products">
        <Product       
          class="product"
          v-for="c in champagnes"
          :key="c.name"
          :product="c"
          />
      </div>
    </section>
</template>

<script>
import Product from '@/components/Product';
export default {
  name: "Champagnes",
    components: {
    Product
  },
  data() {
    return {
      champagnes: [
        {
          name: "philipponnat",
          number: "first",
          folder: "philipponnat",
          logo: "philipponnat_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#bf9e4b',
          type:"champagne"
        },
        {
          name: "aubry",
          number: "third",
          folder: "aubry",
          logo: "aubry_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#f3b615',
          type:"champagne"
        },
        {
          name: "charles",
          number: "second",
          folder: "charles",
          logo: "charlesheidsieck_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#495872',
          type:"champagne"
        },
        {
          name: "deville",
          number: "fourth",
          folder: "deville",
          logo: "deville_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#d2ae59',
          type:"champagne"
        },
        {
          name: "francisOrban",
          number: "fifth",
          folder: "francisOrban",
          logo: "FrancisOrban_dark.svg",
          bottle: "product_4",
          right: true,
          color:'#2e2d2d',
          type:"champagne"
        },
        // {
        //   name: "andreBeaufort",
        //   number: "sixth",
        //   folder: "andreBeaufort",
        //   logo: "AndreBeaufort_dark.svg",
        //   bottle: "product_1",
        //   right: true,
        //   color:'#569dd0',
        //   type:"champagne"
        // },
        {
          name: "cattier",
          number: "seventh",
          folder: "cattier",
          logo: "Cattier_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#dfa799',
          type:"champagne"
        },
        {
          name: "franckPascal",
          number: "eight",
          folder: "franckPascal",
          logo: "FranckPascal_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#ad3238',
          type:"champagne"
        },
        {
          name: "laherteFreres",
          number: "ninth",
          folder: "laherteFreres",
          logo: "LaherteFreres_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#bc8546',
          type:"champagne"
        },
        // {
        //   name: "brunoMichel",
        //   number: "tenth",
        //   folder: "brunoMichel",
        //   logo: "Bruno-Michel_logo_dark.svg",
        //   bottle: "product_1",
        //   right: true,
        //   color:'#955209',
        //   type:"champagne"
        // },
        // {
        //   name: "domaineCollet",
        //   number: "eleven",
        //   folder: "domaineCollet",
        //   logo: "Domaine-Collet_logo_dark.svg",
        //   bottle: "product_1",
        //   right: false,
        //   color:'#E0BD83',
        //   type:"champagne"
        // },
        // {
        //   name: "vincentCouche",
        //   number: "twelve",
        //   folder: "vincentCouche",
        //   logo: "Vincent-Couche_logo_dark.svg",
        //   bottle: "product_1",
        //   right: false,
        //   color:'#778301',
        //   type:"champagne"
        // },
        // {
        //   name: "guyMichelAndFils",
        //   number: "thirteen",
        //   folder: "guyMichelAndFils",
        //   logo: "Guy-Michel_logo_dark.svg",
        //   bottle: "product_1",
        //   right: true,
        //   color:'#1A4401',
        //   type:"champagne"
        // },
        {
          name: "jCharpentier",
          number: "fourteen",
          folder: "jCharpentier",
          logo: "JCharpentier_logo.svg",
          bottle: "product_1",
          right: true,
          color:'#c5ab85',
          type:"champagne"
        },
        {
          name: "casanovaProsecco",
          number: "fifteen",
          folder: "casanovaProsecco",
          logo: "logo-dark.svg",
          bottle: "product_1",
          right: true,
          color:'#FCA36A',
          type:"champagne"
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/brandsPage.scss";
</style>
