<template>
  <div class="content about" v-observe-visibility="visibilityChanged">
    <section class="about__content parent">
      <div class="text__cont" :class="{ text__visible: visible }">
        <div class="first__text">
          <div :class="{ text__open: expandFirst }">
            <section class="mobile__expand">
              <h1>{{ $t("about.title") }}</h1>
              <img
                v-if="isMobile && !expandFirst"
                @click="
                  (expandFirst = !expandFirst),
                    (expandSecond = false),
                    (expandThird = false)
                "
                src="../assets/img/about/more.svg"
                alt="expand"
              />
            </section>
            <p>{{ $t("about.desc") }}</p>
            <!-- v-if="expandFirst" -->
            <p class="p" :class="{ p__visible: expandFirst }">
              {{ $t("about.desc2") }}
            </p>
            <p class="p" :class="{ p__visible: expandFirst }">
              {{ $t("about.desc3") }}
            </p>
          </div>

          <div :class="{ text__open: expandSecond }">
            <section class="mobile__expand">
              <h1>{{ $t("about.title2") }}</h1>
              <img
                v-if="isMobile && !expandSecond"
                @click="
                  (expandSecond = !expandSecond),
                    (expandFirst = false),
                    (expandThird = false)
                "
                src="../assets/img/about/more.svg"
                alt="expand"
              />
            </section>
            <p>{{ $t("about.desc4") }}</p>
            <!-- v-if="expandSecond" -->
            <p class="p" :class="{ p__visible: expandSecond }">
              {{ $t("about.desc5") }}
            </p>
            <p class="p" :class="{ p__visible: expandSecond }">
              {{ $t("about.desc6") }}
            </p>
          </div>
        </div>

        <div class="second__text">
          <div :class="{ text__open: expandThird }">
            <section class="mobile__expand">
              <h1>{{ $t("about.title3") }}</h1>
              <img
                v-if="isMobile && !expandThird"
                @click="
                  (expandThird = !expandThird),
                    (expandFirst = false),
                    (expandSecond = false)
                "
                src="../assets/img/about/more.svg"
                alt="expand"
              />
            </section>
            <p>{{ $t("about.desc7") }}</p>
            <!-- v-if="expandThird" -->
            <p class="p" :class="{ p__visible: expandThird }">
              {{ $t("about.desc8") }}
            </p>
            <p class="p" :class="{ p__visible: expandThird }">
              {{ $t("about.desc9") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <transition name="fade" mode="out-in">
      <section class="images">
        <p class="image" :class="{ image__visible: visible }"></p>
        <p class="imageOver" :class="{ imageOver__visible: visible }"></p>
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      expandFirst: true,
      expandSecond: true,
      expandThird: true,
      isMobile: false,
      visible: false,
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
    isMobileMode() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener("resize", this.isMobileMode);

    if (window.innerWidth < 1024) {
      this.expandFirst = true;
      this.expandSecond = false;
      this.expandThird = false;
    } else {
      this.expandFirst = true;
      this.expandSecond = true;
      this.expandThird = true;
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileMode);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/mainPage.scss";
.content {
  min-height: 1000px;
  .about__content {
    display: flex;
    background: rgba(243, 242, 247, 0.85) 0% 0%;
    position: relative;
    h1 {
      font: normal normal bold 2rem Century;
    }
    &::before {
      background: url("../assets/img/about/h1.jpg") no-repeat;
    }
    .text__cont {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: center;
      text-align: left;
      transform: translateX(-20%);
      @include trHome();

      &.text__visible {
        transform: translateX(0%);
      }
      h1 {
        border-bottom: 2px solid #c8a269;
        white-space: nowrap;
        font: normal normal normal 36px/42px Century;
      }
      p {
        font: normal normal normal 18px Oswald;
      }
      .first__text {
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-left: 100px;
        position: relative;
        z-index: 3;
        div {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        &::before {
          content: "";
          background: url("../assets/img/contacts/quote_1.svg") no-repeat;
          background-position: top;
          background-size: 100%;
          position: absolute;
          top: 0;
          left: 50px;
          height: 100%;
          width: 100px;
          z-index: -2;
        }
      }
      .second__text {
        position: relative;
        z-index: 3;
        div {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 70%;
        }
        &::before {
          content: "";
          background: url("../assets/img/contacts/quote_2.svg") no-repeat;
          background-position: top;
          background-size: 100%;
          position: absolute;
          top: 90%;
          right: 30%;
          height: 100%;
          width: 100px;
          z-index: -2;
        }
      }
      .mobile__expand {
        display: flex;

        h1 {
          width: 100%;
        }
        img {
          margin-left: -20px;
        }
      }
    }
  }
  .images {
    .image {
      background: url("../assets/img/about/h1.jpg") no-repeat;
      background-size: cover;
      background-position: center;
    }
    .imageOver {
      background: url("../assets/img/about/h1.jpg") no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
@media all and(max-width:$mobile) {
  .content {
    min-height: initial;
    .about__content {
      &::before {
        background-size: cover;
      }
      .text__cont {
        display: block;

        .first__text {
          padding: 10px 20px;

          // text expand
          div {
           height: fit-content;
            .p {
              overflow: hidden;
              max-height: 1px;
              &.p__visible {
                max-height: initial;
              }
            }
          }
          // **********
        }
        .second__text {
          margin-bottom: 100px;
          div {
            padding: 10px 20px;
            height: fit-content;
            width: 100%;

            .p {
              overflow: hidden;
              max-height: 1px;
              &.p__visible {
                max-height: initial;
              }
            }
            //*********/
          }
          &::before {
            right: 20px;
          }
        }
      }
    }
  }
}
</style>
