<template>
      <BrandView
    brand="pandaOrganic"
    type="alcohol"
    logo="alcohol/pandaOrganic/logo_light.svg"
    cover="alcohol/pandaOrganic/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "PandaOrganic",
  components: { BrandView },
    data() {
    return {
      PandaOrganicProducts:[
        {route:'/PandaOrganic'},
        {route:'/PandaOrganic'},
      ]
    };
  },
};
</script>