<template>
      <BrandView brand="francisOrban" type="champagne" logo="champagne/francisOrban/logo.svg" cover="champagne/francisOrban/bg.jpg"
    coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "FrancisOrban",
  components: { BrandView },
};
</script>