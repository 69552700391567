<template>
  <BrandView brand="vincentCouche" type="champagne" logo="champagne/vincentCouche/Vincent-Couche_logo_light.svg"
    cover="champagne/vincentCouche/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "VincentCouche",
  components: { BrandView },

};
</script>
