<template>
  <div class="footer">
    <Contacts />

    <div class="footer__cards">
      <section class="salon">
        <a href="/#about"
          ><h1>{{ $t("footer.first.about") }}</h1></a
        >
        <router-link to="/alcohols"
          ><h1>{{ $t("footer.first.alcohol") }}</h1></router-link
        >
        <router-link to="/champagnes"
          ><h1>{{ $t("footer.first.champagne") }}</h1></router-link
        >
        <a @click="scrollTo('contacts')"
          ><h1>{{ $t("footer.first.contacts") }}</h1></a
        >
        <span class="card_label">{{ $t("footer.first.salon") }}</span>
      </section>

      <section class="alcohol">
        <router-link to="/Caffo"
          ><h1>{{ $t("footer.second.caffo") }}</h1></router-link
        >

        <router-link to="/satryna"
          ><h1>{{ $t("footer.second.satryna") }}</h1></router-link
        >

        <router-link to="/scapegrace"
          ><h1>{{ $t("footer.second.scapegrace") }}</h1></router-link
        >

        <router-link to="/knutHansen"
          ><h1>{{ $t("footer.second.knuthansen") }}</h1></router-link
        >

        <router-link to="/Adamus"
          ><h1>{{ $t("footer.second.adamus") }}</h1></router-link
        >

        <router-link to="/PandaOrganic"
          ><h1>{{ $t("footer.second.panda") }}</h1></router-link
        >

        <router-link to="/AlkkemistGin"
          ><h1>{{ $t("footer.second.alkkemist") }}</h1></router-link
        >

        <router-link to="/UnkkodedVodka"
          ><h1>{{ $t("footer.second.unkkoded") }}</h1></router-link
        >

        <router-link to="/TheIllusionist"
          ><h1>{{ $t("footer.second.illusionist") }}</h1></router-link
        >

        <router-link to="/GranChaco"
          ><h1>{{ $t("footer.second.granchaco") }}</h1></router-link
        >

        <router-link to="/EngineGin"
          ><h1>{{ $t("footer.second.engine") }}</h1></router-link
        >

        <span class="card_label">{{ $t("footer.first.alcohol") }}</span>
      </section>

      <section class="champagne">
        <router-link to="/philipponnat"
          ><h1>{{ $t("footer.third.philipponnat") }}</h1></router-link
        >

        <router-link to="/charles"
          ><h1>{{ $t("footer.third.charles") }}</h1></router-link
        >

        <router-link to="/aubry"
          ><h1>{{ $t("footer.third.aubry") }}</h1></router-link
        >

        <router-link to="/deville"
          ><h1>{{ $t("footer.third.deville") }}</h1></router-link
        >

        <router-link to="/FrancisOrban"
          ><h1>{{ $t("footer.third.francisOrban") }}</h1></router-link
        >

        <router-link to="/Cattier"
          ><h1>{{ $t("footer.third.cattier") }}</h1></router-link
        >

        <router-link to="/FranckPascal"
          ><h1>{{ $t("footer.third.franckPascal") }}</h1></router-link
        >

        <router-link to="/LaherteFreres"
          ><h1>{{ $t("footer.third.leherteFreres") }}</h1></router-link
        >

        <router-link to="/JCharpentier"
          ><h1>{{ $t("footer.third.jCharpentier") }}</h1></router-link
        >

        <router-link to="/CasanovaProsecco"
          ><h1>{{ $t("footer.third.CasanovaProsecco") }}</h1></router-link
        >

        <span class="card_label">{{ $t("footer.first.champagne") }}</span>
      </section>
    </div>

    <a href="http://cdots.bg/" target="_blank" class="cdots">{{
      $t("footer.cdots")
    }}</a>
  </div>
</template>

<script>
import Contacts from "@/components/Contacts";
export default {
  name: "Footer",
  components: {
    Contacts,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    isMobileMode() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    scrollTo(id) {
      const target = document.getElementById(id);

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top: target.getBoundingClientRect().top + scrollTop,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener("resize", this.isMobileMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileMode);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
.footer {
  display: flex;
  flex-direction: column;
  gap: 50px;
  background: transparent url("../assets/img/alcohol/brands_bg.jpg") no-repeat;
  background-size: cover;

  .footer__cards {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
  }

  section {
    align-content: center;
    align-items: center;
    border: 2px solid #b59f57;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 30%;
    height: 300px;
    justify-content: center;
    position: relative;
    padding: 10px;
    max-width: 450px;
    min-height: 300px;

    a {
      width: 50%;
    }

    &.salon {
      flex-direction: column;
    }

    .card_label {
      background: #b59f57;
      color: white;
      position: absolute;
      transform: rotate(-90deg);
      left: 0;
      left: -59px;
      padding: 10px 15px;
      line-height: 10px;
      font-size: 18px;
      @include noselect();
    }
    h1 {
      color: #b59f57;
      font: normal normal normal 18px/22px Century;
      padding-bottom: 10px;
      text-transform: uppercase;
      @include tr();
      &:hover {
        text-decoration: underline;
        transform: scale(1.05);
      }
    }
  }

  .cdots {
    align-self: center;
    display: flex;
    font: normal normal normal 15px/42px Century;
    margin-bottom: 20px;
    text-decoration: underline;
    letter-spacing: -1px;
    @include tr();
    &:hover {
      opacity: 0.8;
    }
  }
}
@media all and(max-width:$mobile) {
  .footer__cards {
    flex-direction: column;
    gap: 20px;
    section {
      width: 80%;
      .card_label {
        left: -80px;
      }
      h1 {
        font-size: 1em;
      }
    }
  }
}
</style>
