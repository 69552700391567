<template>
  <BrandView
    brand="caffo"
    type="alcohol"
    logo="alcohol/caffo/caffo_light.svg"
    cover="alcohol/caffo/caffo_bg.jpg"
    coverColor="#00000080"
  />
  <!-- "products":[
                {"name":"VECCHIO AMARO DEL CAPO", "route": "/capo"},
                {"name":"CAFFO LIQUORICE LIQUEUR", "route": "/liquorice"},
                {"name":"CAFFO SAN MARZANO", "route": "/marzano"},
                {"name":"CAFFO LIMONCINO DELL’ISOLA", "route": "/limoncino"},
                {"name":"VECCHIO AMARO DEL CAPO RED HOT EDITION", "route": "/capoRed"}
            ] -->
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Caffo",
  components: { BrandView },
};
</script>
