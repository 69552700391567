<template>
  <BrandView
    logoSize="350px"
    brand="philipponnat"
    type="champagne"
    logo="champagne/philipponnat/philipponnat_light.svg"
    cover="champagne/philipponnat/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Philipponnat",
  components: { BrandView },
};
</script>
