<template>
  <BrandView logoSize="400px" brand="franckPascal" type="champagne" logo="champagne/franckPascal/logo.png"
    cover="champagne/franckPascal/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "FranckPascal",
  components: { BrandView },
};
</script>
