<template>
    <BrandView brand="laherteFreres" type="champagne" logo="champagne/laherteFreres/logo.svg" cover="champagne/laherteFreres/bg.jpg"
    coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "LaherteFreres",
  components: { BrandView },
};
</script>
