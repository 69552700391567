<template>
  <div class="content" v-observe-visibility="visibilityChanged">
    <section class="images">
      <p class="image" :class="{ image__visible: visible }"></p>
      <p class="imageOver" :class="{ imageOver__visible: visible }"></p>
    </section>

    <section class="alcohol__content parent">
      <div
        class="text__alcohol parent__text"
        :class="{ text__visible: visible }"
      >
        <h1>{{ $t("alcohol.title") }}</h1>
        <p>{{ $t("alcohol.desc") }}</p>
        <p>{{ $t("alcohol.desc2") }}</p>
      </div>

      <div
        class="alcohol__products parent__products"
        :class="{ text__visible: visible }"
      >
        <Product       
          v-for="a in alcohols"
          :key="a.name"
          :product="a"
          />

        <router-link to="/alcohols" class="to__brands">
          <main>
            <section>
              <img src="@/assets/img/home/next.svg" alt="" />
              <div class="text">
                <h1>{{ $t("alcohol.title") }}</h1>
                <p>{{ $t("alcohol.desc3") }}</p>
              </div>
            </section>
          </main>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import Product from '@/components/Product';
export default {
  name: "Alcohol",
      components: {
    Product
  },
  data() {
    return {
      visible: false,

      alcohols: [
        {
          name: "caffo",
          number: "first",
          folder: "caffo",
          logo: "caffo_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#b59f57',
          type:"alcohol"
        },
        {
          name: "satryna",
          number: "second",
          folder: "satryna",
          logo: "satryna_logo.png",
          bottle: "product_1",
          right: true,
          color:'#d4944e',
          type:"alcohol"
        },
        {
          name: "scapegrace",
          number: "third",
          folder: "scapegrace",
          logo: "scapegrace_logo_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#515151',
          type:"alcohol"
        },
        {
          name: "adamus",
          number: "fourth",
          folder: "adamus",
          logo: "adamus_logo_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#B09064',
          type:"alcohol"
        },
      ],
    };
  },
  methods: {
    visibilityChanged(v) {
      this.visible = v;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/mainPage.scss";
.content {
  height: 140vh;
  min-height: 1500px;
  .images {
    .image {
      background: url("../../assets/img/alcohol/h2.jpg") no-repeat;
      background-size: cover;
      background-position: center;
    }
    .imageOver {
      background: url("../../assets/img/alcohol/h2.jpg") no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
  .alcohol__content {
    &::before {
      content: "";
      background: url("../../assets/img/alcohol/h2.jpg") no-repeat;
    }
    .text__alcohol {
      transform: translateX(20%);

      &::before {
        background: url("../../assets/img/contacts/quote_1.svg") no-repeat;
      }
    }
    .alcohol__products {
      transform: translateX(20%);
    }
  }
}
@media all and(max-width:$mobile) {
  .content {
    height: 100%;
    flex-direction: column;
  }
}
</style>
