<template>
    <BrandView brand="deville" type="champagne" logo="champagne/deville/deville_light.svg" cover="champagne/deville/deville_bg.jpg"
    coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Deville",
  components: { BrandView },
};
</script>
