<template>
  <BrandView brand="jCharpentier" type="champagne" logo="champagne/jCharpentier/JCharpentier_logo.svg"
    cover="champagne/jCharpentier/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "JCharpentier",
  components: { BrandView },
};
</script>
