<template>
  <router-link
    :to="`/${product.name}`"
    class="product"
    :class="{ rightProduct: product.right }"
    :style="{ border: `4px solid${product.color}` }"
  >
    <template v-if="product.right">
      <div class="logo-wrap">
        <img
          class="logo"
          :src="
            require(`../assets/img/${product.type}/${product.folder}/${product.logo}`)
          "
          alt="product logo"
        />
      </div>
      <img
        class="center__bottle"
        :src="
          require(`@/assets/img/${product.type}/${product.folder}/${product.bottle}.png`)
        "
        alt="product bottle"
      />
    </template>

    <div class="product-text" :style="{ background: product.color }">
      <section>
        <h1>{{ $t(`${product.type}.${product.number}-bottle.title`) }}</h1>
        <p class="description">{{ $t(`${product.type}.${product.number}-bottle.desc`) }}</p>
      </section>
      <p class="more--info">
        {{ $t(`moreInfo`) }}
      </p>
    </div>

    <template v-if="!product.right">
      <img
        class="center__bottle"
        :src="
          require(`@/assets/img/${product.type}/${product.folder}/${product.bottle}.png`)
        "
        alt="product bottle"
      />
      <div class="logo-wrap">
        <img
          class="logo"
          :src="
            require(`../assets/img/${product.type}/${product.folder}/${product.logo}`)
          "
          alt="product logo"
        />
      </div>
    </template>
  </router-link>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.product {
  display: flex;
  height: 200px;
  justify-content: space-between;
  max-width: 700px;
  position: relative;
  @include tr();

  //hover
  &:hover {
    transform: scale(0.98);
    .center__bottle {
      height: 140%;
    }
    .product-text {
      .more--info {
        letter-spacing: 1px;
        &::after {
          transform: scale(0.5);
        }
      }
    }
  }
  //hover

  &.rightProduct {
    .product-text {
      margin-right: -10px;
    }
  }
  .logo-wrap {
    display: flex;
    justify-content: center;
    width: 50%;
    @include tr();

    img {
      padding: 20px;
      max-width: 250px;
      object-fit: scale-down;
      width: 100%;
    }
  }
  .product-text {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    min-height: fit-content;
    margin: 10px 0;
    margin-left: -10px;
    width: 50%;
    @include tr();
    h1 {
      font: normal normal normal 26px Century;
      padding: 10px 10px 0 10px;
    }
    .description {
      font: normal normal normal 16px/20px Oswald;
      padding-top: 15px;
      margin: 0 auto;
      width: 90%;
    }
    .more--info {
      font-size: 12px;
      letter-spacing: 1.2px;
      padding-bottom: 10px;
      &::after {
        content: "";
        background-color: white;
        display: block;
        height: 2px;
        transform: scale(0.7);
        @include tr();
      }
    }
  }

  .center__bottle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 130%;
    @include tr();
  }
}
@media all and(max-width:$mobile) {
  .product {
    height: fit-content;
    flex-direction: column-reverse;
    margin: 30px 8px;
    width: 45%;
    &:hover {
      transform: initial;
      .product-text {
        .more--info {
          letter-spacing: 1.2px;
          &::after {
            transform: scale(0.7);
          }
        }
      }
    .center__bottle {
      height: 300px;
    }
    }
    &.rightProduct {
      flex-direction: column;
    }
    .product-text {
      padding: 40px 0 10px 0;
      height: fit-content;
      margin: -40px auto -30px auto !important;
      word-break: break-word;
      width: 90%;
      h1 {
        font-size: 20px;
        height: 60px;
      }
      .description {
        padding: 15px 0;
        font-size: 13px !important;
        height: 120px;
      }
    .more--info {
        font-size: 11px !important;
        width: 100%;
       }
    }
    .logo-wrap {
      height: 180px;
      width: 100%;
    }

    .center__bottle {
      transform: initial;
      position: initial;
      height: 300px;
      object-fit: scale-down;
      z-index: 2;
    }
  }
}
@media all and(min-width:2000px) {
  .product {
    min-height: 20%;
   max-width: initial;
    .center__bottle {
      height: 110%;
    }
        .logo-wrap {
      img{
        width: 100%;
      }
    }
    .product-text {
      justify-content: space-evenly;
    }
  }
}
</style>
