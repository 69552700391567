import { render, staticRenderFns } from "./Cuvee.vue?vue&type=template&id=f08741ae&scoped=true&"
import script from "./Cuvee.vue?vue&type=script&lang=js&"
export * from "./Cuvee.vue?vue&type=script&lang=js&"
import style0 from "./Cuvee.vue?vue&type=style&index=0&id=f08741ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f08741ae",
  null
  
)

export default component.exports