import { render, staticRenderFns } from "./Marzano.vue?vue&type=template&id=ba9f1054&scoped=true&"
import script from "./Marzano.vue?vue&type=script&lang=js&"
export * from "./Marzano.vue?vue&type=script&lang=js&"
import style0 from "./Marzano.vue?vue&type=style&index=0&id=ba9f1054&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba9f1054",
  null
  
)

export default component.exports