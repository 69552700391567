import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

//Alcohol
import Caffo from '../views/alcohol/Caffo.vue'
// import N0 from '../views/alcohol/N0.vue'
import Satryna from '../views/alcohol/Satryna.vue'
import Scapegrace from '../views/alcohol/Scapegrace.vue'
import KnutHansen from '../views/alcohol/KnutHansen.vue'
import Adamus from '../views/alcohol/Adamus.vue'
import PandaOrganic from '../views/alcohol/PandaOrganic.vue'
import AlkkemistGin from '../views/alcohol/AlkkemistGin.vue'
import UnkkodedVodka from '../views/alcohol/UnkkodedVodka.vue'
import TheIllusionist from '../views/alcohol/TheIllusionist.vue'
import GranChaco from '../views/alcohol/GranChaco.vue'
import EngineGin from '../views/alcohol/EngineGin.vue'

//Products Alcohol
import Alcohols from '../views/Alcohols.vue'

import Capo from '../views/alcohol/products/Capo.vue'
import CapoRed from '../views/alcohol/products/CapoRed.vue'
import Liquorice from '../views/alcohol/products/Liquorice.vue'
import Marzano from '../views/alcohol/products/Marzano.vue'
import Limoncino from '../views/alcohol/products/Limoncino.vue'
import FrenchVodka from '../views/alcohol/products/FrenchVodka.vue'
import LondonGin from '../views/alcohol/products/LondonGin.vue'
import SatrynaCristalino from '../views/alcohol/products/SatrynaCristalino.vue'
import SatrynaTequilaBlanco from '../views/alcohol/products/SatrynaTequilaBlanco.vue'
import SatrynaTequilaReposados from '../views/alcohol/products/SatrynaTequilaReposado.vue'
import SatrynaTequilaMezcal from '../views/alcohol/products/SatrynaTequilaMezcal.vue'
import SatrynaTequilaAnejo from '../views/alcohol/products/SatrynaTequilaAnejo.vue'
import ScapegraceClassic from '../views/alcohol/products/ScapegraceClassic.vue'
import ScapegraceBlack from '../views/alcohol/products/ScapegraceBlack.vue'
import ScapegraceGold from '../views/alcohol/products/ScapegraceGold.vue'

//Champagne
import Champagnes from '../views/Champagnes.vue'

import Philipponnat from '../views/champagne/Philipponnat.vue'
import Charles from '../views/champagne/Charles.vue'
import Aubry from '../views/champagne/Aubry.vue'
import Deville from '../views/champagne/Deville.vue'
import FrancisOrban from '../views/champagne/FrancisOrban.vue'
import AndreBeaufort from '../views/champagne/AndreBeaufort.vue'
import Cattier from '../views/champagne/Cattier.vue'
import FranckPascal from '../views/champagne/FranckPascal.vue'
import LaherteFreres from '../views/champagne/LaherteFreres.vue'
import BrunoMichel from '../views/champagne/BrunoMichel.vue'
import DomaineCollet from '../views/champagne/DomaineCollet.vue'
import VincentCouche from '../views/champagne/VincentCouche.vue'
import GuyMichelAndFils from '../views/champagne/GuyMichelAndFils.vue'
import JCharpentier from '../views/champagne/JCharpentier.vue'
import CasanovaProsecco from '../views/champagne/CasanovaProsecco.vue'

//Products Champagne
import FOBrutPrestige from '../views/champagne/products/FOBrutPrestige.vue'
import RoyaleBrut from '../views/champagne/products/RoyaleBrut.vue'
import RoyaleDose from '../views/champagne/products/RoyaleDose.vue'
import RoyaleRose from '../views/champagne/products/RoyaleRose.vue'
import BlancDeNoirs from '../views/champagne/products/BlancDeNoirs.vue'
import BlancExtra from '../views/champagne/products/BlancExtra.vue'
import Cuvee from '../views/champagne/products/Cuvee.vue'
import CuveeRose from '../views/champagne/products/CuveeRose.vue'
import CHBrut from '../views/champagne/products/CHBrut.vue'
import CHRose from '../views/champagne/products/CHRose.vue'
import CHBlancs from '../views/champagne/products/CHBlancs.vue'
import CHRoseMillesime from '../views/champagne/products/CHRoseMillesime.vue'
import CHBrutMillesime from '../views/champagne/products/CHBrutMillesime.vue'
import CHBlancMillenaires from '../views/champagne/products/CHBlancMillenaires.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // ALCOHOLS
  {
    path: '/alcohols',
    name: 'Alcohols',
    component: Alcohols
  },
  {
    path: '/caffo',
    name: 'Caffo',
    component: Caffo
  },
  {
    path: '/capo',
    name: 'Vecchio Amaro Del Capo',
    component: Capo
  },
  {
    path: '/capoRed',
    name: 'Vecchio Amaro Del Capo Red Hot Edition',
    component: CapoRed
  },
  {
    path: '/liquorice',
    name: 'Liquorice',
    component: Liquorice
  },
  {
    path: '/marzano',
    name: 'San Marzano',
    component: Marzano
  },
  {
    path: '/limoncino',
    name: 'Limoncino',
    component: Limoncino
  },
  {
    path: '/frenchVodka',
    name: 'French Vodka',
    component: FrenchVodka
  },
  {
    path: '/londonGin',
    name: 'London Gin',
    component: LondonGin
  },
  // {
  //   path: '/n0',
  //   name: 'Number Zero',
  //   component: N0
  // },
  {
    path: '/satryna',
    name: 'Satryna',
    component: Satryna
  },
  {
    path: '/scapegrace',
    name: 'Scapegrace',
    component: Scapegrace
  },
  {
    path: '/Scapegrace-Gin-Classic',
    name: 'Scapegrace Gin Classic',
    component: ScapegraceClassic
  },
  {
    path: '/Scapegrace-Gin-Black',
    name: 'Scapegrace Gin Black',
    component: ScapegraceBlack
  },
  {
    path: '/Scapegrace-Gin-Gold',
    name: 'Scapegrace Gin Gold',
    component: ScapegraceGold
  },
  {
    path: '/Satryna-Cristalino',
    name: 'Satryna Cristalino',
    component: SatrynaCristalino
  },
  {
    path: '/Satryna-Tequila-Blanco',
    name: 'Satryna Tequila Blanco',
    component: SatrynaTequilaBlanco
  },
  {
    path: '/Satryna-Tequila-Reposado',
    name: 'Satryna Tequila Reposado',
    component: SatrynaTequilaReposados
  },
  {
    path: '/Satryna-Tequila-Anejo',
    name: 'Satryna Tequila Anejo',
    component: SatrynaTequilaAnejo
  },
  {
    path: '/Satryna-Mezcal-Artеsanal-Cenizo',
    name: 'Satryna Mezcal Artеsanal-Cenizo',
    component: SatrynaTequilaMezcal
  },
  {
    path: '/KnutHansen',
    name: 'Knut Hansen',
    component: KnutHansen
  },
  {
    path: '/Adamus',
    name: 'Adamus',
    component: Adamus
  },
  {
    path: '/PandaOrganic',
    name: 'Panda Organic Gin',
    component: PandaOrganic
  },
  {
    path: '/AlkkemistGin',
    name: 'Alkkemist Gin',
    component: AlkkemistGin
  },
  {
    path: '/UnkkodedVodka',
    name: 'Unkkoded Vodka',
    component: UnkkodedVodka
  },
  {
    path: '/TheIllusionist',
    name: 'The Illusionist Dry Gin',
    component: TheIllusionist
  },
  {
    path: '/GranChaco',
    name: 'Gran Chaco',
    component: GranChaco
  },
  {
    path: '/EngineGin',
    name: 'Engine Gin',
    component: EngineGin
  },
  // ALCOHOLS

  // CHAMPAGNES
  {
    path: '/champagnes',
    name: 'Champagnes',
    component: Champagnes
  },
  {
    path: '/philipponnat',
    name: 'Philipponnat',
    component: Philipponnat
  },
  {
    path: '/charles',
    name: 'Charles Heidsieck',
    component: Charles
  },
  {
    path: '/Charles-Heidsieck-Brut-Reserve',
    name: 'Brut Reserve',
    component: CHBrut
  },
  {
    path: '/Charles-Heidsieck-Rose-Reserve',
    name: 'Rose Reserve',
    component: CHRose
  },
  {
    path: '/Charles-Heidsieck-Blanc-de-Blancs',
    name: 'Blanc de Blancs',
    component: CHBlancs
  },
  {
    path: '/Charles-Heidsieck-Rose-Millesime',
    name: 'Rosé Millésime',
    component: CHRoseMillesime
  },
  {
    path: '/Charles-Heidsieck-Brut-Millesime',
    name: 'Brut Millesime',
    component: CHBrutMillesime
  },
  {
    path: '/Charles-Heidsieck-Blanc-des-Millenaires',
    name: 'Blanc des Millenaires',
    component: CHBlancMillenaires
  },
  {
    path: '/aubry',
    name: 'Aubry',
    component: Aubry
  },
  {
    path: '/deville',
    name: 'Deville',
    component: Deville
  },
  {
    path: '/Philipponnat-Royale-Reserve-Brut',
    name: 'Royale Brut',
    component: RoyaleBrut
  },
  {
    path: '/Royale-Reserve-Non-Dose',
    name: 'Royale Dose',
    component: RoyaleDose
  },
  {
    path: '/Royale-Reserve-Rose',
    name: 'Royale Rose',
    component: RoyaleRose
  },
  {
    path: '/Blanc-de-Noirs-Vintage',
    name: 'Blanc De Noirs',
    component: BlancDeNoirs
  },
  {
    path: '/Grand-Blanc-Extra-Brut-Vintage',
    name: 'Blanc Extra',
    component: BlancExtra
  },
  {
    path: '/Cuvee-1522-Vintage',
    name: 'Cuvee 1522 Vintage',
    component: Cuvee
  },
  {
    path: '/Cuvee-1522-Rose-Vintage',
    name: 'Cuvee 1522 Rose Vintage',
    component: CuveeRose
  },
  {
    path: '/FrancisOrban',
    name: 'Francis Orban',
    component: FrancisOrban
  },
  {
    path: '/FrancisOrban-Brut-Prestige',
    name: 'FrancisOrban Brut Prestige',
    component: FOBrutPrestige
  },
  {
    path: '/AndreBeaufort',
    name: 'Andre Beaufort',
    component: AndreBeaufort
  },
  {
    path: '/Cattier',
    name: 'Cattier',
    component: Cattier
  },
  {
    path: '/FranckPascal',
    name: 'Franck Pascal',
    component: FranckPascal
  },
  {
    path: '/LaherteFreres',
    name: 'Laherte Freres',
    component: LaherteFreres
  },
  {
    path: '/BrunoMichel',
    name: 'Bruno Michel',
    component: BrunoMichel
  },
  {
    path: '/DomaineCollet',
    name: 'Domaine Collet',
    component: DomaineCollet
  },
  {
    path: '/VincentCouche',
    name: 'Vincent Couche',
    component: VincentCouche
  },
  {
    path: '/GuyMichelAndFils',
    name: 'Guy Michel & Fils',
    component: GuyMichelAndFils
  },
  {
    path: '/JCharpentier',
    name: 'J Charpentier',
    component: JCharpentier
  },
  {
    path: '/CasanovaProsecco',
    name: 'Casanova Prosecco',
    component: CasanovaProsecco
  },
  // CHAMPAGNES
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to,from,next) => {
  if(to.name !== 'Home'){
    document.title=`Salon 2011 - ${to.name}`
  }else{
    document.title=`Salon 2011`
  }
  next()
})

export default router
