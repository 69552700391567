<template>
  <BrandView logoSize="initial" class="sub__product__page--satryna" brand="satryna" type="alcohol"
    logo="alcohol/satryna/satryna_logo.png" cover="alcohol/satryna/satryna_bg.png" coverColor="#19243880" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Satryna",
  components: { BrandView },
};
</script>
