<template>
  <div class="sub__product__page">
    <section class="info" :style="{
      background: coverColor ? coverColor : '',
    }">
      <router-link to="/">
        <img class="logo" src="@/assets/img/logo.svg" alt="Salon2011 Logo" />
      </router-link>

      <img class="sub__product__logo" v-if="logo" 
      :style="logoSize ? { width: logoSize } : '' "
      :src="require(`@/assets/img/${logo}`)" alt="" />

      <img class="sub__product__cover" v-if="cover" :src="require(`@/assets/img/${cover}`)" alt="" />

      <div class="sub__product__text">
        <template v-for="(m, i) in $t(`${type}.${brand}.info`)">
          <p v-if="m.title" v-html="m.title" :key="`title-info-${i}`"></p>
          <span v-if="m.txt" v-html="m.txt" :key="`txt-info-${i}`"></span>
        </template>
      </div>
    </section>
    <template v-if="$te(`${type}.${brand}.products`)">
      <section class="products__container">
        <div class="frozen">
          <p>{{ $t(`${type}.${brand}.title`) }}</p>
        </div>
        <div class="products">
          <section>
            <div v-for="(product, i) in $t(`${type}.${brand}.products`)" :key="`product-${i + 1}-route-${product.route}`">
              <router-link class="sub__product" :to="product.route">
                <img :src="`${require(`@/assets/img/${type}/${brand}/product_${i + 1
                    }.png`)}`" :alt="`${brand} Bottle`" />
              </router-link>

              <router-link :to="product.route" class="sub__product__title">
                <h1>{{ product.name }}</h1>
              </router-link>
            </div>
          </section>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
export default {
  name: "BrandView",
  props: ["type", "brand", "logo", "cover", "coverColor", "logoSize"],
};
</script>
<style lang="scss" scoped>
// @import "@/scss/base.scss";
@import "@/scss/subProducts.scss";
</style>
