<template>
  <div id="app">
    <AgeWarning />
    <Locale
    />
      <!-- :class="{
        dark:
          this.$route.name === 'Home' ||
          // alcohol
          this.$route.name === 'Aubry' ||
          this.$route.name === 'Caffo' ||
          this.$route.name === 'Charles Heidsieck' ||
          this.$route.name === 'Deville' ||
          this.$route.name === 'Number Zero' ||
          this.$route.name === 'Philipponnat' ||
          this.$route.name === 'Satryna' ||
          this.$route.name === 'Satryna Cristalino' ||
          this.$route.name === 'Scapegrace' ||
          this.$route.name === 'Knut Hansen' ||
          this.$route.name === 'Adamus' ||
          this.$route.name === 'Gran Chaco' ||
          this.$route.name === 'Engine Gin' ||
          //champagne
          this.$route.name === 'Francis Orban' ||
          this.$route.name === 'Andre Beaufort' ||
          this.$route.name === 'Cattier' ||
          this.$route.name === 'Franck Pascal' ||
          this.$route.name === 'Laherte Freres' ||
          this.$route.name === 'Bruno Michel' ||
          this.$route.name === 'Domaine Collet' ||
          this.$route.name === 'Vincent Couche' ||
          this.$route.name === 'Guy Michel & Fils' ||
          this.$route.name === 'J Charpentier',
      }" -->
    <vue-snotify></vue-snotify>
    <router-view />
    <TheFooter
      id="contacts"
      v-if="
        this.$route.name === 'Home' ||
        //alcohol
        this.$route.name === 'Caffo' ||
        this.$route.name === 'Number Zero' ||
        this.$route.name === 'Satryna' ||
        this.$route.name === 'Scapegrace' ||
        this.$route.name === 'Knut Hansen' ||
        this.$route.name === 'Adamus' ||
        this.$route.name === 'Panda Organic Gin' ||
        this.$route.name === 'Alkkemist Gin' ||
        this.$route.name === 'Unkkoded Vodka' ||
        this.$route.name === 'The Illusionist Dry Gin' ||
        this.$route.name === 'Gran Chaco' ||
        this.$route.name === 'Engine Gin' ||
        //champagne
        this.$route.name === 'Philipponnat' ||
        this.$route.name === 'Charles Heidsieck' ||
        this.$route.name === 'Aubry' ||
        this.$route.name === 'Deville' ||
        this.$route.name === 'Francis Orban' ||
        this.$route.name === 'Andre Beaufort' ||
        this.$route.name === 'Cattier' ||
        this.$route.name === 'Franck Pascal' ||
        this.$route.name === 'Laherte Freres' ||
        this.$route.name === 'Bruno Michel' ||
        this.$route.name === 'Domaine Collet' ||
        this.$route.name === 'Vincent Couche' ||
        this.$route.name === 'Guy Michel & Fils' ||
        this.$route.name === 'J Charpentier'
      "
    />
  </div>
</template>

<script>
import Locale from "@/components/Locale";
import AgeWarning from "@/components/AgeWarning";
import TheFooter from "@/components/TheFooter";
export default {
  components: {
    AgeWarning,
    Locale,
    TheFooter,
  },
  watch: {
    $route() {
      window.scroll(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/base.scss";
@import "~vue-snotify/styles/material";
@import "@/scss/snotify.scss";

@font-face {
  font-family: "Century";
  src: url("./assets/fonts/CENTURY.TTF") format("truetype"),
    url("./assets/fonts/CENTURY.TTF") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Light.ttf") format("truetype"),
    url("./assets/fonts/Oswald-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Oswald";
  src: url("./assets/fonts/Oswald-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
#app {
  font-family: "Oswald", Century, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  text-align: center;
}
html {
  box-sizing: border-box;
  color: #000;
  font-family: Oswald, Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}
input,
textarea {
  font: normal normal bold 15px/42px Oswald;
}
</style>
