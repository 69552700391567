<template>
      <BrandView
    brand="unkkodedVodka"
    type="alcohol"
    logo="alcohol/unkkodedVodka/logo-light.png"
    cover="alcohol/unkkodedVodka/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "UnkkodedVodka",
  components: { BrandView },
};
</script>
