<template>
  <main class="product__container">
    <div class="header">
      <div class="back" @click="back()">
        <img src="@/assets/img/contacts/back.svg" alt="" />
      </div>
      <router-link to="/"
        ><img class="logo" src="@/assets/img/logo-b.svg" alt=""
      /></router-link>
      <p></p>
    </div>

    <div class="product__content">
      <div v-if="!isMobile" class="product__image">
        <div class="img__wrapper">
          <img src="@/assets/img/alcohol/caffo/removed/product_4.png" alt="" />
        </div>
      </div>

      <div class="product__text">
        <div class="container">
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <h1>{{ $t("alcohol.limoncino.title") }}</h1>
              <div v-if="isMobile" class="wrap">
                <img src="@/assets/img/alcohol/caffo/removed/product_4.png" alt=""
                />
              </div>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("alcohol.limoncino.desc") }}</p>
              <span>{{ $t("alcohol.limoncino.paragraph") }}</span>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("alcohol.limoncino.desc2") }}</p>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("alcohol.limoncino.desc3") }}</p>
            </div>
          </div>

          <img
            v-if="!isMobile"
            class="map"
            src="../../../assets/img/alcohol/caffo/removed/product_4_map.svg"
            alt=""
          />
        </div>
      </div>
      <img
        class="map__mobile"
        v-if="isMobile"
        src="../../../assets/img/alcohol/caffo/removed/product_4_map.svg"
        alt=""
      />
    </div>
  </main>
</template>

<script>
export default {
  name: "Limoncino",
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    back() {
      history.back();
    },
    isMobileMode() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener("resize", this.isMobileMode);
    
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileMode);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/productPage.scss";
.product__container {
  background: hsl(32, 56%, 95%, 0.8);

  &::before {
    content: "";
    background: url("../../../assets/img/alcohol/caffo/caffo_product_bg.jpg")
      no-repeat;
    background-position: right;
    background-size: cover;
  }
  .header {
    .back {
      background: #C8A269;
    }
  }

  .product__content {
    .product__image {
      .img__wrapper {
        border: 17px solid #C8A269;
        &:hover{
        border: 5px solid #C8A269;
        }
      }
    }
    .product__text {
      .container {
        .timeline-content {
          .wrap {
            border: 15px solid $limoncinoColor;
          }
        }
        &:before {
          content: "";
          background: $limoncinoColor;
        }

        .map {
          width: 206px;
          height: 253px;
          z-index: 3;
          position: relative;
          margin-left: -90px;
        }
        h1 {
          color: $limoncinoColor;
        }

        .marker {
          border: 5px solid $limoncinoColor;
        }
      }
    }
  }
}
</style>
