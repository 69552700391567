<template>
    <BrandView brand="cattier" type="champagne" logo="champagne/cattier/logo.svg" cover="champagne/cattier/bg.jpg"
    coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Cattier",
  components: { BrandView },

};
</script>