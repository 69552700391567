<template>
    <BrandView brand="andreBeaufort" type="champagne" logo="champagne/andreBeaufort/logo.svg" cover="champagne/andreBeaufort/bg.jpg"
    coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "AndreBeaufort",
  components: { BrandView },
};
</script>
