<template>
  <main class="product__container">
    <div class="header">
      <div class="back"  @click="back()">
      <img
        src="@/assets/img/contacts/back.svg"
        alt=""
      />
      </div>
      <router-link to="/"
        ><img class="logo" src="@/assets/img/logo-b.svg" alt=""
      /></router-link>
      <p></p>
    </div>

    <div class="product__content">
      <div v-if="!isMobile" class="product__image">
        <div class="img__wrapper">
          <img src="@/assets/img/alcohol/n0/product_1.png" alt="" />
        </div>
      </div>

      <div class="product__text">
        <div class="container">
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <h1>{{ $t("alcohol.frenchV.title") }}</h1>
              <div v-if="isMobile" class="wrap">
              <img src="@/assets/img/alcohol/n0/product_1.png" alt="">
              </div>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("alcohol.frenchV.desc") }}</p>
              <span>{{ $t("alcohol.frenchV.paragraph") }}</span>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("characteristics.visual") }}</p>
              <span>{{ $t("alcohol.frenchV.span") }}</span>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("characteristics.nose") }}</p>
              <span>{{ $t("alcohol.frenchV.span2") }}</span>
            </div>
          </div>
          <div class="timeline-block">
            <div class="marker"></div>
            <div class="timeline-content">
              <p>{{ $t("characteristics.taste") }}</p>
              <span>{{ $t("alcohol.frenchV.span3") }}</span>
            </div>
          </div>
          <img  v-if="!isMobile" src="../../../assets/img/alcohol/n0/product_1_map.svg" alt="" />
        </div>
      </div>
      <img
      class="map__mobile"
        v-if="isMobile"
        src="../../../assets/img/alcohol/n0/product_1_map.svg"
        alt=""
      />
    </div>
  </main>
</template>

<script>
export default {
  name: "FrenchVodka",
   data() {
    return {
      isMobile: false,
    };
  },
  methods: {
       back() {
      history.back();
    },
    isMobileMode() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener("resize", this.isMobileMode);
    
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileMode);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/productPage.scss";
.product__container {
  background: hsl(0, 0%, 93%,0.9);

  &::before {
    content: "";
    background: url("../../../assets/img/alcohol/n0/n0_bg.jpg")
      no-repeat;
    background-position: right;
    background-size: cover;
  }
  .header {
    .back {
      background: $FrenchVodkaColor;
    }
  }

  .product__content {
    .product__image {
      .img__wrapper {
        border: 17px solid $FrenchVodkaColor;
        &:hover{
        border: 5px solid $FrenchVodkaColor;
        }
      }
    }
    .product__text {
      .container {
      .timeline-content {
          .wrap {
            border: 15px solid $FrenchVodkaColor;
          }
        }
        &:before {
          content: "";
          background: $FrenchVodkaColor;
        }

        h1 {
          color: $FrenchVodkaColor;
        }

        .marker {
          border: 5px solid $FrenchVodkaColor;
        }
      }
    }
  }
}
</style>
