<template>
  <div class="content__contacts">
    <div class="map">
      <GmapMap
        ref="gmap"
        :center="center"
        :zoom="16"
        style="width: 100%; height: 100%"
        :options="{
          disableDefaultUI: true,
          styles: mapStyle,
        }"
        map-type-id="terrain"
        class="gmap"
      >
        <gmap-custom-marker
          :clickable="true"
          :draggable="true"
          :marker="marker"
        >
          <a
            class="qsa"
            href="https://www.google.com/maps/place/Salon+2011/@42.6749948,23.3042166,15z/data=!4m5!3m4!1s0x0:0xf2ae4019f43f713!8m2!3d42.6748557!4d23.3042309"
            target="_blank"
          >
            <img src="../assets/img/contacts/location.svg" />
          </a>
        </gmap-custom-marker>
      </GmapMap>
    </div>

    <div class="contacts">
      <img class="logo" src="../assets/img/logo-w.svg" alt="" />
      <p class="dash"></p>
      <a
        class="address link__hover"
        href="https://www.google.com/maps/place/Salon+2011/@42.6749948,23.3042166,15z/data=!4m5!3m4!1s0x0:0xf2ae4019f43f713!8m2!3d42.6748557!4d23.3042309"
        target="_blank"
        ><p>{{ $t("contacts.address") }}</p></a
      >
      <p class="dash"></p>

      <div class="phone__email">
        <div>
          <!-- https://api.whatsapp.com/send?phone=359885628200 -->
          <a href="tel:+359 885 628200" target="_blank" class="link__hover">
            <img src="../assets/img/contacts/phone.svg" alt="" />
            <strong>+359 885 628200</strong>
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=359885628200"
            target="_blank"
            class="link__hover"
          >
            <img src="../assets/img/contacts/whatsapp.svg" alt="" />
            <strong>0885 628200</strong>
          </a>
        </div>
        <div>
          <a href="tel:+359 2 4458701" target="_blank" class="link__hover">
            <img src="../assets/img/contacts/phone.svg" alt="" />
            <strong>+359 2 4458701</strong>
          </a>
          <a href="mailto:office@salon2011.com" class="link__hover">
            <img src="../assets/img/contacts/mail.svg" alt="" />
            <strong>office@salon2011.com</strong>
          </a>
        </div>
      </div>
      <p class="dash"></p>

      <form class="form">
        <p class="form__title">{{ $t("contacts.contactUs") }}</p>

        <div class="form__input">
          <input
            v-model="form.name"
            type="text"
            :placeholder="$t('contacts.name')"
          />

          <input
            v-model="form.email"
            type="email"
            :placeholder="$t('contacts.email')"
          />

          <input
            v-model="form.message"
            type="text"
            :placeholder="$t('contacts.message')"
          />
        </div>
        <p class="submit" @click="sendEmail()">{{ $t("contacts.submit") }}</p>
      </form>
    </div>
  </div>
</template>

<script>
// import Email from "../assets/js/email.js";
import googleMapStyles from "@/assets/google-map-styles/google-map-styles.json";
import GmapCustomMarker from "vue2-gmap-custom-marker";
export default {
  name: "Contacts",
  components: {
    "gmap-custom-marker": GmapCustomMarker,
  },
  data() {
    return {
      mapStyle: googleMapStyles,
      center: {
        lat: 42.67485691465944,
        lng: 23.30423079999998,
      },
      marker: {
        lat: 42.67494379546807,
        lng: 23.30427181844418,
      },
     form: {
        name: "",
        email: "",
        message: "",
      },
      isMobile: false,
    };
  },
  methods: {
    isValid() {
      if (!this.form.name) {
        this.$snotify.warning('Въведете правилно име');
        return false;
      } else if (!this.form.email || !this.validateEmail(this.form.email)) {
        this.$snotify.warning('Въведете коректен имейл');
        return false;
      } else if (!this.form.message) {
        this.$snotify.warning('Оставете ни съобщение');
        return false;
      }

        return true;
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(String(email).toLowerCase());
    },
        sendEmail() {
      if (this.isValid()) {
        fetch(`${process.env.VUE_APP_BASE_URI}/email/inquiry`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(this.form),
        })
          .then((response) => response.json())
          .then((data) => {
            this.success = data.success;
            if (data.success) {
              this.form = {
                name: "",
                email: "",
                message: ""
              };
            }
          })
          .catch((err) => console.log(err))
          .then(() =>
            setTimeout(() => {
              this.$snotify.success("Изпратихте успешно запитване");
            }, 1000)
          );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
.content__contacts {
  display: flex;
  .map {
    width: 50%;
    position: relative;
    &::after {
      border: 4px solid #b59f57;
      content: "";
      height: 80%;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      position: absolute;
      left: 50%;
      width: 80%;
    }
  }
  .contacts {
    align-items: center;
    background: #b59f57;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;
    text-align: center;
    padding: 20px;
    width: 50%;

    .dash {
      background: oldlace;
      height: 1px;
      width: 60px;
    }
    .address {
      font: normal normal normal 24px/24px Century;
      width: 50%;
    }

    .phone__email {
      div {
        display: flex;
        font: normal normal normal 16px Oswald;
        justify-content: center;
        flex-wrap: wrap;
        padding: 2px;
        gap: 10px;
      }
      a {
        display: flex;
        align-items: center;
        gap: 5px;
        width: 45%;
        white-space: nowrap;
      }
    }
      .logo {
        max-width: 250px;
        width: 100%;
      }
    .form {
      max-width: 340px;
      width: 90%;

      .form__title {
        font: normal normal normal 24px/24px Century;
        padding-bottom: 20px;
      }
      .form__input {
        display: flex;
        flex-direction: column;
        gap: 5px;
        input {
          background: transparent;
          border: 2px solid #ffffff;
          color: white;
          font: normal normal normal 15px Oswald;
          height: 40px;
          outline: none;

          &::placeholder {
            color: white;
            opacity: 0.8;
          }
        }
        :nth-child(3) {
          height: 103px;
        }
      }
      .submit {
        cursor: pointer;
        font-size: 12px;
        padding-top: 20px;
        margin: 0 auto;
        width: fit-content;
        font: normal normal bold 12px Oswald;
        @include tr();
        &::after {
          content: "";
          display: block;
          height: 2px;
          background-color: white;
          transform: scale(0.7);
          @include tr();
        }
        &:hover {
          letter-spacing: 1px;

          &::after {
            transform: scale(0.5);
          }
        }
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .content__contacts {
    flex-direction: column-reverse;
    .map {
      height: 500px;
      width: 100%;
    }
    .contacts {
      width: 100%;
      .address {
        width: 100%;
      }
      .logo {
        width: 100%;
        padding: 10px;
      }
    }
  }
}
@media all and(max-width:350px) {
  .content__contacts {
    .contacts {
      .phone__email {
        div {
          font: normal normal normal 12px Oswald;
        }
      }
    }
  }
}
</style>
