<template>
  <BrandView brand="casanovaProsecco" type="champagne" logo="champagne/casanovaProsecco/logo-light.svg"
    cover="champagne/casanovaProsecco/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "CasanovaProsecco",
  components: { BrandView },
};
</script>
