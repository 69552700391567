<template>
  <BrandView
    brand="scapegrace"
    type="alcohol"
    logo="alcohol/scapegrace/scapegrace_logo_inside.svg"
    cover="alcohol/scapegrace/Scapegrace_bg.jpg"
    coverColor="#192438cc"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Scapegrace",
  components: { BrandView },
};
</script>

