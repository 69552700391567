<template>
      <BrandView
    brand="engineGin"
    type="alcohol"
    logo="alcohol/engineGin/logo.svg"
    cover="alcohol/engineGin/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "EngineGin",
  components: { BrandView },
};
</script>
