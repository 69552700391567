<template>
    <BrandView
    brand="adamus"
    type="alcohol"
    logo="alcohol/adamus/adamus_logo_light.svg"
    cover="alcohol/adamus/bg.jpg"
    coverColor="#00000080"
  />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Adamus",
  components: { BrandView },
};
</script>
