<template>
  <BrandView brand="charles" type="champagne" logo="champagne/charles/charlesheidsieck_light.svg"
    cover="champagne/charles/bg.jpg" coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Charles",
  components: { BrandView },

};
</script>