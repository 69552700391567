<template>
    <section class="products__content">
      <div class="header__products">
        <router-link to="/" class="logo">
          <img class="logo" src="../assets/img/logo-b.svg" alt="logo" />
        </router-link>
        <h1>{{ $t("alcohol.title") }}</h1>
        <p>{{ $t("alcohol.desc") }}</p>
      </div>

      <div class="products">
        <Product       
          class="product"
          v-for="a in alcohols"
          :key="a.name"
          :product="a"
          />
      </div>
    </section>
</template>

<script>
import Product from '@/components/Product';
export default {
  name: "Alcohols",
    components: {
    Product
  },
  data() {
    return {
      alcohols: [
        {
          name: "caffo",
          number: "first",
          folder: "caffo",
          logo: "caffo_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#b59f57',
          type:"alcohol"
        },
        {
          name: "satryna",
          number: "second",
          folder: "satryna",
          logo: "satryna_logo.png",
          bottle: "product_1",
          right: true,
          color:'#d4944e',
          type:"alcohol"
        },
        {
          name: "scapegrace",
          number: "third",
          folder: "scapegrace",
          logo: "scapegrace_logo_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#515151',
          type:"alcohol"
        },
        {
          name: "Adamus",
          number: "fourth",
          folder: "adamus",
          logo: "adamus_logo_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#B09064',
          type:"alcohol"
        },
        {
          name: "KnutHansen",
          number: "fifth",
          folder: "knutHansen",
          logo: "KnutHansen_Logo_dark.svg",
          bottle: "product_1",
          right: true,
          color:'#0D87C2',
          type:"alcohol"
        },
        // {
        //   name: "n0",
        //   number: "sixth",
        //   folder: "n0",
        //   logo: "n0_dark.svg",
        //   bottle: "product_1",
        //   right: true,
        //   color:'#000000',
        //   type:"alcohol"
        // },
        {
          name: "PandaOrganic",
          number: "seventh",
          folder: "pandaOrganic",
          logo: "logo_dark.svg",
          bottle: "product_1",
          right: false,
          color:'#000000',
          type:"alcohol"
        },
        {
          name: "AlkkemistGin",
          number: "eight",
          folder: "alkkemistGin",
          logo: "logo.png",
          bottle: "product_1",
          right: false,
          color:'#d4944e',
          type:"alcohol"
        },
        {
          name: "UnkkodedVodka",
          number: "ninth",
          folder: "unkkodedVodka",
          logo: "logo-dark.png",
          bottle: "product_1",
          right: true,
          color:'#28446C',
          type:"alcohol"
        },
        {
          name: "TheIllusionist",
          number: "tenth",
          folder: "theIllusionist",
          logo: "logo_dark.png",
          bottle: "product_1",
          right: true,
          color:'#CFB89C',
          type:"alcohol"
        },
        {
          name: "GranChaco",
          number: "eleventh",
          folder: "granChaco",
          logo: "logo.svg",
          bottle: "product_1",
          right: false,
          color:'#592353',
          type:"alcohol"
        },
        {
          name: "EngineGin",
          number: "twelve",
          folder: "engineGin",
          logo: "logo.svg",
          bottle: "product_1",
          right: false,
          color:'#8E1B16',
          type:"alcohol"
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
@import "@/scss/brandsPage.scss";
.products__content {
  
    &::before {
      content: "";
      background: url("../assets/img/home/h2.jpg") no-repeat;
      background-size: cover;
    }
}
</style>
