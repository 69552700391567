<template>
  <div class="home">
    <main class="video__container" v-observe-visibility="visibilityChanged">
      <div class="video">
        <video playsinline autoplay muted loop>
          <source src="../assets/img/home/bg.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="video__content">
        <img
          class="logo"
          src="../assets/img/logo.svg"
          alt="logo"
          :class="{ logo__visible: visible }"
        />

        <section class="home__title">
          <img
            v-if="isMobile"
            :src="
              require(`../assets/img/home/premium_m_${this.$i18n.locale}.svg`)
            "
            alt=""
          />
          <div v-if="!isMobile">
            <div class="premium__brand">
              <img
                class="premium"
                :class="{ first__visible: visible }"
                :src="require(`../assets/img/home/p1_${this.$i18n.locale}.svg`)"
                alt="Premium Text"
              />
              <img
                class="brand"
                :class="{ first__visible: visible }"
                :src="require(`../assets/img/home/p2_${this.$i18n.locale}.svg`)"
                alt="Brands Text"
              />
            </div>
            <img
              class="alcohol"
              :class="{ first__visible: visible }"
              :src="require(`../assets/img/home/p3_${this.$i18n.locale}.svg`)"
              alt="Alcohol Text"
            />
          </div>
        </section>

        <p class="desc" :class="{ first__visible: visible }">
          {{ $t("home.desc") }}
        </p>

        <section class="links" :class="{ first__visible: visible }">
          <a @click="scrollTo('about')">
            <div class="links__box">
              <p>{{ $t("home.link1") }}</p>
              <p>{{ $t("home.link1-desc") }}</p>
            </div>
          </a>

          <a @click="scrollTo('alcohol')">
            <div class="links__box">
              <p>{{ $t("home.link2") }}</p>
              <p>{{ $t("home.link2-desc") }}</p>
            </div>
          </a>

          <a @click="scrollTo('champagne')">
            <div class="links__box">
              <p>{{ $t("home.link3") }}</p>
              <p>{{ $t("home.link3-desc") }}</p>
            </div>
          </a>

          <a @click="scrollTo('contacts')">
            <div class="links__box">
              <p>{{ $t("home.link4") }}</p>
              <p>{{ $t("home.link4-desc") }}</p>
            </div>
          </a>
        </section>
      </div>
    </main>
    <About id="about" />
    <Alcohol id="alcohol" />
    <Champagne id="champagne" />
  </div>
</template>

<script>
import About from "@/views/About";
import Alcohol from "@/views/alcohol/Alcohol";
import Champagne from "@/views/Champagne";

export default {
  name: "Home",
  components: {
    About,
    Alcohol,
    Champagne,
  },
  data() {
    return {
      isMobile: false,
      visible: false,
    };
  },
  methods: {
    scrollTo(id) {
      const target = document.getElementById(id);

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      window.scrollTo({
        top: target.getBoundingClientRect().top + scrollTop,
        behavior: "smooth",
      });
    },
    visibilityChanged(v) {
      this.visible = v;
    },
    isMobileMode() {
      if (window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
  mounted() {
    this.isMobileMode();
    window.addEventListener("resize", this.isMobileMode);
  },
  destroyed() {
    window.removeEventListener("resize", this.isMobileMode);
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
.video__container {
  height: 101vh;
  position: relative;
  min-height: 700px;
  overflow: hidden;
  z-index: 9;

  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -10;
    video {
      min-width: 100vw;
      width: fit-content;
    }
  }
  .video__content {
    align-items: center;
    background: rgba(21, 21, 21, 0.85) 0% 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    @include noselect();
    .first__visible {
      transform: translateX(0%);
    }
  }
  .logo {
    transform: translateY(80%);
    width: 700px;
    @include trHome();
    &.logo__visible {
      transform: translateY(0%);
      width: 300px;
    }
  }
  .home__title {
    .premium__brand {
      display: flex;
      gap: 20px;
      padding: 10px 0;
      .premium {
        margin-top: -50px;
        transform: translateX(-300%);
        @include trHome();
        &.first__visible {
          transform: translateX(0%);
        }
      }
      .brand {
        transform: translateX(300%);
        @include trHome();

        &.first__visible {
          transform: translateX(0%);
        }
      }
    }
    .alcohol {
      transform: translateY(500%);
      @include trHome();
      &.first__visible {
        transform: translateY(0%);
      }
    }
  }
  .desc {
    color: white;
    font: normal normal normal 24px/30px "Oswald";
    transform: translateY(500%);
    width: 50%;
    @include trHome();
  }
  .links {
    display: flex;
    color: white;
    gap: 10px;
    transform: translateY(500%);
    @include trHome();

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 60px;
      width: 220px;
      &:hover {
        .links__box {
          height: 0;
        }
      }
    }
    .links__box {
      border: 2px solid #b58c7b;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      width: 100%;
      @include transition(0.7s);
      :nth-child(1) {
        letter-spacing: 1px;
        font: normal normal normal 16px Century;
      }
      :nth-child(2) {
        font: normal normal normal 14px Century;
        font-style: italic;
      }
    }
  }
}
@media all and(max-width:$mobile) {
  .video__container {
    height: 1100px;

    &::before {
      content: "";
      background: url("../assets/img/home/home-mobile-bg.jpg") no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -20;
      width: 100%;
      height: 100%;
    }
    .video {
      display: none;
    }
    .video__content {
      background: rgba(21, 21, 21, 0.85) 0% 0%;
      gap: 30px;
      .logo {
        width: 100%;
        &.logo__visible {
          transform: translateY(0%);
          width: 300px;
        }
      }
    }
    .desc {
      width: 85%;
    }
    .links {
      flex-wrap: wrap;
      padding: 0 10px;
      a {
        width: 48%;
      }
    }
  }
}
</style>
