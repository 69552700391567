<template>
  <BrandView brand="aubry" type="champagne" logo="champagne/aubry/aubry_light.svg" cover="champagne/aubry/aubry_bg.jpg"
    coverColor="#00000080" />
</template>

<script>
import BrandView from "@/components/BrandView";
export default {
  name: "Aubry",
  components: { BrandView },
};
</script>
